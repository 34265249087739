/* 
FONT STYLE
*/

.petiText_nav {
    font-family: "Petit Formal Script", serif;
    font-size: 2vh !important;
    font-weight: 400;
}


.frame-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16rem;
    background-color: white;
    color: black;
    font-family: Arial, sans-serif;
}

.nav-title {
    height: 8rem;
    overflow: hidden;
}

.nav_link {
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 15px;
    cursor: pointer;
    color: #4d3303;
}

.img-logo {
    max-height: 100%;
    height: 25rem;
    width: 25rem;
    object-fit: cover;
}

.language-button {
    background-color: none;
    background: none;
    color: black;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.language-button:hover {
    color: #e5c67c;
    transform: scale(1.2);
    text-decoration: underline;
}

/* 
  * Hamburger Menù  
*/

.hamburger {
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
    height: 25px;
    cursor: pointer;
    z-index: 200;
}

.hamburger span {
    display: block;
    height: 3px;
    background: #000;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
}

.hamburger.open span:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
}

/* Menu Styles */
.menu {
    position: fixed;
    top: 0;
    right: -110vw;
    width: 100vw;
    height: 100vh;
    background: #f3e8d4ea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: right 0.4s ease-in-out;
    z-index: 100;
}

.menu.menu-open {
    right: 0;
}

body.menu-active {
    overflow: hidden;
}

/* Stile della lista */
.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.menu ul li {
    margin: 20px 0;
}

.menu ul li a {
    color: #4d3303;
    font-size: 1.5rem;
    text-decoration: none;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.menu ul li a:hover {
    text-decoration: underline;
    transform: scale(1.2);
}

/* Mostra l'hamburger menu solo su mobile */
@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .frame-group {
        display: flex;
        align-items: center;
        background-color: white;
        color: black;
        font-family: Arial, sans-serif;
    }
}

/* Animazione hamburger aperto */
.hamburger.open span:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
}