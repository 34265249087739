h1, h2, h3, p {
  margin: 0;
  padding: 0;
}

.app{
  width: fit-content;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

img {
  max-width: 100%;
  height: auto;
}

/* Layout generico */
.container {
  width: 90%;
  margin: 0 auto;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Media query per tablet */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .container {
    width: 100%;
  }

  .flex {
    flex-direction: column;
  }
}

/* Media query per dispositivi mobili */
@media (max-width: 420px) {
  body {
    font-size: 12px;
  }

  h1 {
    font-size: 24px;
  }

  .container {
    width: 100%;
    padding: 0 10px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
