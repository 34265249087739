/* 
FONT PAGE 
*/

.petiText_font {
  font-family: "Petit Formal Script", serif;
  font-size: 2rem !important;
  font-weight: 400;
  color: #c58618 !important;
}

.philosopher_font {
  font-family: "Philosopher", serif;
  font-size: 2rem !important;
  font-weight: 400;
  color: #C58618 !important;
}

.catamText_fontDescrp {
  font-family: "Catamaran", serif;
  font-size: 2vh;
  font-weight: 400;
  color: #4d3303;
  padding-right: 4rem;
}

.catamText_fontMemories {
  font-family: "Catamaran", serif;
  font-size: 4vh;
  font-weight: 400;
  color: #4d3303;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

/* 
END FONT PAGE 
*/

.travel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: #fefffe;
  padding-top: 30px;
}

.travel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13rem;
  max-width: 800px;
  width: 100%;
}

.travel-item.reverse {
  flex-direction: row-reverse;
}

.travel-description {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.travel-description h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.travel-description p {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
}

.travel-image {
  flex: 1;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about_button {
  font-family: "Catamaran", serif;
  left: 10px;
  font-size: 20px;
  border-radius: 60%;
  padding: 20px;
  margin-top: 3rem;
  background: transparent;
  color: black;
  border: 1px solid #ebcf88;
  cursor: pointer;
}

.about_button:hover {
  color: #e5c67c;
  transform: scale(1.2);
}

/* 
REDEFINING LUXURY SECTION 
*/

.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  color: white;
}

.main-title {
  display: flex;
  justify-content: center;
  font-family: "Catamaran", serif;
  font-size: 15.4vh;
  font-weight: 200;
  padding-top: 0px;
  margin-bottom: -3rem;
  color: #4d3303;
}

.subtitle {
  display: flex;
  justify-content: center;
  font-family: "Petit Formal Script", serif;
  font-size: 11vh;
  font-weight: 400;
  padding-top: 0px;
  margin-bottom: 3rem;
  color: #4d3303;
}

.description {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Catamaran", serif;
  font-size: 4vh;
  font-weight: 200;
  padding-top: 3rem;
  padding-right: 5rem;
  padding-left: 5rem;
  color: #4d3303;
}

.link-prop {
  font-family: "Catamaran", serif;
  font-size: 15.4vh;
  font-weight: 200;
  position: absolute;
  bottom: 30px;
  right: 10px;
  padding: 10px 20px;
  font-size: 2rem;
  border-radius: 5px;
  background: transparent;
  border: none;
  transition: transform 0.3s ease, color 0.3s ease;
  text-decoration: none;
  color: #4d3303;
  cursor: pointer;
}

.link-prop:hover {
  color: #338B55;
}

.link-prop.active {
  color: #338B55;
  transform: scale(1.5);
}

/* 
TESTIMONIAL SECTION 
*/

.testimonial-container {
  text-align: center;
  padding: 50px 20px;
  width: 90%;
}

.testimonial-title {
  font-family: "Philosopher", serif;
  font-size: 2rem !important;
  font-weight: 400;
  color: #C58618 !important;
  margin-bottom: 10px;
}

.title-description {
  font-family: "Catamaran", serif;
  font-size: 1.5rem !important;
  font-weight: 400;
  color: #4d3303 !important;
  max-width: 600px;
  margin: 0 auto 30px;
}

.testimonial-description {
  font-family: "Catamaran", serif;
  font-size: 0.8rem !important;
  font-weight: 400;
  color: #4d3303 !important;
  max-width: 600px;
}

.button-testimonial {
  font-family: "Catamaran", serif;
  font-size: 1.5rem !important;
  font-weight: 400;
  color: #4d3303 !important;
  margin-top: 2rem;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: black;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.testimonial-name {
  font-family: "Catamaran", serif;
  font-size: 1.2rem !important;
  font-weight: 400;
  color: #4d3303 !important;
  font-weight: bold;
}

.testimonial-img {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
}

.testimonial-rating {
  font-size: 1.2rem;
  color: #f5a623;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
}

.small-card {
  width: 16rem;
  height: 16.8rem;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
  padding: 2rem;
  border: none !important;
  transition: transform 0.3s ease-in-out;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Card Center (in evidenza) */
.large-card {
  width: 19rem;
  height: 22rem;
  background: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  border: none !important;
  transition: transform 0.3s ease-in-out;
  box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.2);
}

/* Hover effect */
.card:hover {
  transform: scale(1.05);
}