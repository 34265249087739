/* 
FONT PAGE
*/
.petiText_title {
    font-family: "Petit Formal Script", serif;
    font-size: 3.5rem !important;
    font-weight: 400;
    color: #4d3303 !important;
}

.philosopherText_subtitle {
    font-family: "Philosopher", serif;
    font-size: 3rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_description {
    font-family: "Catamaran", serif;
    font-size: 2vh;
    font-weight: 400;
    padding-right: 22.8rem;
    padding-left: 22.8rem;
    padding-top: 2rem;
    color: #4d3303;
}

.philosopherText_title {
    font-family: "Philosopher", serif;
    font-size: 3.5rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_subtitle {
    font-family: "Catamaran", serif;
    font-size: 3rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_description2 {
    font-family: "Catamaran", serif;
    font-size: 2.5vh;
    font-weight: 400;
    padding-right: 22.8rem;
    padding-left: 22.8rem;
    padding-top: 1rem;
    color: #4d3303;
}

.titleRoots_phils {
    font-family: "Philosopher", serif;
    font-size: 2rem;
    font-weight: 400;
    background: #338B55;
    background: linear-gradient(to right, #338B55 0%, #0E2517 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*
 SLOW TOURS SECTION
*/

.journey-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
}

.slowTours_section {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.containerImg_tours {
    display: flex;
    gap: 2rem;
    padding: 5rem;
}

.imgLeft {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 10rem;
    margin-top: 1rem;
}

.imgRight {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 10rem;
}

/* 
TAYLORED
*/

.taylored-container {
    padding-left: 3rem;
    padding-right: 3rem;
}

.taylored-title {
    display: flex;
    justify-content: center;
    font-family: "Catamaran", serif;
    font-size: 10vh;
    font-weight: 200;
    margin-bottom: -2rem;
    background: linear-gradient(to right, #338B55 0%, #0E2517 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.taylored-section {
    position: relative;
    width: 100%;
    height: 50vh;
    z-index: -10;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    color: white;
}

.taylored-description {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: "Catamaran", serif;
    font-size: 2.5vh;
    font-weight: 400;
    padding: 3rem;
    padding-right: 5rem;
    padding-left: 5rem;
    color: #4d3303;
}

/* 
BACK TO THE ROOTS
*/

.section_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* Centra il contenuto */
    gap: 40px;
    padding: 50px 20px;
    /* Più padding sui bordi laterali */
    flex-wrap: wrap;
}

.reverse {
    flex-direction: row-reverse;
    /* Inverte l'ordine degli elementi */
}

.block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* Centra il contenuto all'interno del blocco */
    gap: 20px;
}

.journeyPhoto img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 500px;
    /* Limita la larghezza massima dell'immagine */
}

.text-section,
.text-section-reverse {
    padding: 0 20px;
    max-width: 600px;
    text-align: center;
    /* Centra il testo */
}

.text-section h2,
.text-section-reverse h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    /* Colore più scuro per migliorare la visibilità */
}

.descriptionRoots {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    max-width: 600px;
    /* Limita la larghezza del testo */
    margin: 0 auto;
    /* Centra il paragrafo */
}