.slideshow-container {
  position: relative;
  margin-bottom: 40px;
  overflow: hidden;
}

.slide {
  display: none;
  animation: fade 1.5s;
}

.slide.active {
  display: block;
}

.slideshow-image {
  width: 90vw;
  height: 38.5vw;
  max-width: 111rem;
  max-height: 70rem;
  object-fit: cover;
}

/* Indicatori numerici */
.slideshow-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  font-family: 'Catamaran', serif;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  margin: 0 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.indicator:hover {
  color: #e5c67c;
}

.indicator.active {
  color: #e5c67c;
  transform: scale(1.5);
}

/* Media query per dispositivi più piccoli */
@media (max-width: 768px) {
  .slideshow_button {
    font-size: 16px;
    padding: 8px 16px;
  }

  .carousel-image {
    width: 90vw;
    height: 40vw;
    max-width: none;
    max-height: none;
  }

  .indicator {
    font-size: 14px;
  }
}

/* Media query per smartphone */
@media (max-width: 480px) {
  .slideshow_button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .carousel-image {
    width: 100%;
    height: auto;
    max-width: none;
    max-height: none;
  }

  .indicator {
    font-size: 12px;
  }
}

/* Animazione */
@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}