/* 
FONT PAGE
*/
.petiText_title {
    font-family: "Petit Formal Script", serif;
    font-size: 3.5rem !important;
    font-weight: 400;
    color: #4d3303 !important;
}

.philosopherText_subtitle {
    font-family: "Philosopher", serif;
    font-size: 3rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_description {
    font-family: "Catamaran", serif;
    font-size: 2vh;
    font-weight: 400;
    padding-right: 22.8rem;
    padding-left: 22.8rem;
    padding-top: 2rem;
    color: #4d3303;
}

.philosopherText_title {
    font-family: "Philosopher", serif;
    font-size: 3.5rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_subtitle {
    font-family: "Catamaran", serif;
    font-size: 3rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_description2 {
    font-family: "Catamaran", serif;
    font-size: 2.5vh;
    font-weight: 400;
    padding-right: 22.8rem;
    padding-left: 22.8rem;
    padding-top: 1rem;
    color: #4d3303;
}

.titleWeddings_phils {
    font-family: "Philosopher", serif;
    font-size: 2rem;
    font-weight: 400;
    background: #EFD78F;
    background: linear-gradient(to right, #EFD78F 0%, #C58618 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* 
OUT CREATION
*/
.OurCreation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;
}

@media (min-width: 768px) {
    .OurCreation {
        flex-direction: row;
    }
}

.left-creation,
.right-creation {
    width: 100%;
}

@media (min-width: 768px) {

    .left-creation,
    .right-creation {
        width: 50%;
    }
}

.left-creation {
    display: flex;
    justify-content: center;
}

.left-image {
    width: 60vh;
    height: 80vh;
    /* object-fit: cover; */
}

.right-creation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

@media (min-width: 768px) {
    .right-creation {
        text-align: center;
    }
}

.imgCreation {
    width: 90vh;
    max-width: 450px;
    height: auto;
    object-fit: contain;

}

.textCreation_catamaran {
    font-family: "Catamaran", serif;
    font-size: 2vh;
    font-weight: 400;
    color: #4d3303;
}

/*
 SLOW TOURS SECTION
*/

.slowTours_section {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.containerImg_tours {
    display: flex;
    gap: 2rem;
    padding: 5rem;
}

.imgLeft {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 10rem;
    margin-top: 1rem;
}

.imgRight {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 10rem;
}

/* 
TAYLORED
*/

.taylored-container {
    padding-left: 3rem;
    padding-right: 3rem;
}

.taylored-title {
    display: flex;
    justify-content: center;
    font-family: "Catamaran", serif;
    font-size: 10vh;
    font-weight: 200;
    margin-bottom: -2rem;
    background: linear-gradient(to right, #338B55 0%, #0E2517 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.taylored-section {
    position: relative;
    width: 100%;
    height: 50vh;
    z-index: -10;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    color: white;
}

.taylored-description {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: "Catamaran", serif;
    font-size: 2.5vh;
    font-weight: 400;
    padding: 3rem;
    padding-right: 5rem;
    padding-left: 5rem;
    color: #4d3303;
}

/* 
BACK TO THE ROOTS
*/

.roots_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.descriptionRoots {
    font-family: "Catamaran", serif;
    font-size: 1.6vh;
    font-weight: 400;
    padding-top: 2vh;
}

.weddingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 50px 20px;
    flex-wrap: wrap;
}

.reverse {
    flex-direction: row-reverse;
}

.weddingPhoto img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 500px;
}

.weddingSection,
.weddingSection-inverse {
    padding: 0 20px;
    max-width: 600px;
    text-align: center;
}

.weddingSection h2,
.weddingSection-inverse h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.weddingSection p,
.weddingSection-inverse p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    max-width: 600px;
    margin: 0 auto;
}


/* 
BUTTON CONTACT
*/

.about_button {
    font-family: "Catamaran", serif;
    left: 10px;
    font-size: 20px;
    border-radius: 60%;
    padding: 20px;
    margin-top: 3rem;
    background: transparent;
    color: black;
    border: 1px solid #ebcf88;
    cursor: pointer;
}

.about_button:hover {
    color: #e5c67c;
    transform: scale(1.2);
}