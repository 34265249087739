/* 
FONT PAGE
*/
.philosopherTitle {
    font-family: "Philosopher", serif;
    font-size: 3rem;
    font-weight: 400;
    color: #4d3303;
}

.philosopherPhil {
    font-family: "Philosopher", serif;
    font-size: 2rem;
    font-weight: 400;
    padding-top: 2rem;
    color: #4d3303;
}

.petiText_title {
    font-family: "Petit Formal Script", serif;
    font-size: 3.5rem !important;
    font-weight: 400;
    color: #4d3303 !important;
}

.catamaran_title {
    font-family: "Catamaran", serif;
    font-size: 3rem;
    font-weight: 300;
    color: #4d3303;
}

.philosopherOurWorld_descrp {
    font-family: "Philosopher", serif;
    font-size: 2.5vh;
    font-weight: 400;
    padding-top: 2rem;
    color: #4d3303;
}

.philosopherOffer_descrp {
    font-family: "Philosopher", serif;
    font-size: 3vh;
    font-weight: 400;
    padding-top: 2rem;
    color: #4d3303;
}

.catamaranText_subtitle {
    font-family: "Catamaran", serif;
    font-size: 3rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_description2 {
    font-family: "Catamaran", serif;
    font-size: 2.5vh;
    font-weight: 400;
    padding-right: 22.8rem;
    padding-left: 22.8rem;
    padding-top: 1rem;
    color: #4d3303;
}

/* 
welcome section
*/
.welcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.welcome-img {
    position: relative;
    margin-bottom: 40px;
    overflow: hidden;
    width: clamp(200px, 100vw, 1600px);
}

.containerImg_boat {
    display: flex;
    padding: 5rem;
}

/* 
* Who we are
*/

.containerImg_founder {
    display: flex;
    gap: 2rem;
    padding: 5rem;
    justify-content: center;
}


/* 
* PHILOSOPHY
*/

.philosophy_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 5rem;
}

.text_container {
    flex: 1;
    text-align: left;
}

.CreateFont {
    font-family: "Philosopher", serif !important;
    font-size: 3rem !important;
    font-weight: 400 !important;
    padding-top: 2rem !important;
    color: #4d3303 !important;
}

.containerImg_philosophy {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.imgRight {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 3.5rem;
}

.catamaranText_philosophy {
    font-family: "Catamaran", serif;
    font-size: 2.5vh;
    font-weight: 400;
    padding-top: 2rem;
    color: #4d3303;
}

/* 
* OFFER
*/

.section_WhatDo {
    text-align: center;
    padding: 50px 20px;
}

.cards_container {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 3rem;
}

.card_WhatDo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    height: fit-content !important;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border: none !important;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.3);
}

.imgOffer {
    width: 94% !important;
    padding-top: 1rem;
}

.logoCard {
    height: 5rem !important;
    width: 10rem !important;
    object-fit: contain;
}

.card_WhatDo:hover {
    transform: translateY(-10px);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.3);
}

/* 
MISSION SECTION
*/
.mission-container {
    padding: 3rem;
}

.mission-title {
    display: flex;
    justify-content: center;
    font-family: "Catamaran", serif;
    font-size: 15.4vh;
    font-weight: 200;
    margin-bottom: -3rem;
    color: #4d3303;
}

.mission-subtitle {
    display: flex;
    justify-content: center;
    font-family: "Philosopher", serif;
    font-size: 7vh;
    font-weight: 200;
    padding-top: 4rem;
    color: #4d3303;
}

.mission-description {
    display: flex;
    justify-content: center;
    font-family: "Catamaran", serif;
    font-size: 3vh;
    font-weight: 200;
    padding: 1rem;
    color: #4d3303;
}

.secondMission-description {
    display: flex;
    justify-content: center;
    font-family: "Philosopher", serif;
    font-size: 3vh;
    font-weight: 200;
    padding: 1rem;
    color: #4d3303;
}

/* 
CREATE
 */

.section-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    padding: 20px;
    text-align: center;
}

.image-box {
    width: 40vh;
    height: 45vh;
    background-size: cover;
    background-position: center;
}

.box-left {
    position: absolute;
    left: 0;
    transform: translateY(30%) translateX(60%) rotate(12deg);
}

.box-right {
    position: absolute;
    right: 0;
    transform: translateY(110%) translateX(-60%) rotate(-12deg);
}

.text-container {
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.text-container h1 {
    font-size: 32px;
    font-family: serif;
    font-weight: bold;
    margin-bottom: 10px;
}

.text-container p {
    font-size: 18px;
    color: #444;
    margin-bottom: 20px;
}

.contact-button {
    font-family: "Catamaran", serif;
    font-size: 20px;
    border-radius: 60%;
    padding: 20px;
    width: 10rem;
    background: transparent;
    color: black;
    border: 1px solid #ebcf88;
    cursor: pointer;
    text-decoration: none;
}

.contact-button:hover {
    color: #e5c67c;
    transform: scale(1.2);
}