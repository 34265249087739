* {
    font-family: 'Catamaran', serif;
    font-size: 0.9rem;
    font-weight: 200;
    color: #4d3303;
}

.catamaranTitle-footer{
    font-family: 'Catamaran', serif;
    font-size: 1rem;
    font-weight: 400;
    color: #4d3303;
}

/* Stile generale del footer */
.footer {
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
}

.logo_principal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
}

.second_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    
}

.logo_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 80%;
    width: 30%;
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.logo_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    min-width: 400px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start; 
    gap: 5px;
}

/* Colonne del footer */
.footer-column {
    flex: auto;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* margin-left: 1rem;  */
}

.catamaranTitle-footer {
    font-weight: bold;
    font-size: 18px;
    margin-bottom:2rem; /* Spazio sotto il titolo */
    margin-top: 5rem;
}

.footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-column li {
    margin-bottom: 8px;
}

.footer-column a {
    text-decoration: none;
    color: #333;
}

.footer-column a:hover {
    text-decoration: underline;
}


/* Sezione Copyright */
.footer-bottom {
    display: flex;
    justify-content:space-between;
    align-items: center;
    text-align: center;
    border-top: 0.3px solid #4d3303;
    margin-bottom: 4px;
    height: 6vh;
    font-size: 1.4vh;
    padding-left: 1rem;
    padding-right: 1rem;
}

/* 📱 MEDIA QUERIES */
@media (max-width: 1024px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-column {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }

    .footer-bottom div {
        margin-top: 10px;
    }
}