/* .formStyle {
    border: 0.5px solid #C58618;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    border-radius: 1.3rem;
    width: 50vh;
    height: 50vh;
} */

.contact-form-container {
    max-width: 150vh;
    width: 70vh;
    height: 80vh;
    margin: auto;
    padding: 2rem;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #C58618;
}

input,
textarea {
    width: 100%;
    padding: 8px;
    border: none;
    border-bottom: 1px solid #c8a165;
    outline: none;
    background: transparent;
}

textarea {
    min-height: 100px;
    border-radius: 5px;
    border: 1px solid #c8a165;
}

.checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
}

button {
    margin-top: 2rem;
}

.titleInput {
    margin-top: 2rem;
}

.buttonSubmit {
    align-self: center;
    font-family: "Catamaran", serif;
    display: flex;
    justify-content: center;
    font-size: 19px;
    border-radius: 60%;
    padding: 1rem;
    margin-top: 3rem;
    width: 30vh;
    background: transparent;
    color: black;
    border: 1px solid #ebcf88;
    cursor: pointer;
}

.buttonSubmit:hover {
    color: #e5c67c;
    transform: scale(1.2);
}

.checkboxForm {
    display: flex;
    align-items: center;
    float: left;
    gap: 0.5rem;
}