/* 
FONT PAGE
*/
.petiText_title {
    font-family: "Petit Formal Script", serif;
    font-size: 3.5rem !important;
    font-weight: 400;
    color: #4d3303 !important;
}

.philosopherText_subtitle {
    font-family: "Philosopher", serif;
    font-size: 3rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_description {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Catamaran", serif;
    font-size: 2vh;
    font-weight: 400;
    padding-right: 22.8rem;
    padding-left: 22.8rem;
    padding-top: 2rem;
    color: #4d3303;
}

.philosopherText_title {
    font-family: "Philosopher", serif;
    font-size: 3.5rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_subtitle {
    font-family: "Catamaran", serif;
    font-size: 3rem;
    font-weight: 400;
    color: #4d3303;
}

.catamaranText_description2 {
    font-family: "Catamaran", serif;
    font-size: 2vh;
    font-weight: 400;
    padding-right: 22.8rem;
    padding-left: 22.8rem;
    padding-top: 1rem;
    color: #4d3303;
}

.titleArchitect_phils {
    font-family: "Philosopher", serif;
    font-size: 2rem;
    font-weight: 400;
    background: #F91514;
    background: linear-gradient(to right, #F91514 0%, #930C0C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.numberRoots {
    font-size: 3rem;
}

/* 
MISSION SECTION
*/

.vision-container {
    padding: 3rem;
}

.vision-title {
    display: flex;
    justify-content: center;
    font-family: "Catamaran", serif;
    font-size: 12vh;
    font-weight: 200vh;
    margin-bottom: -3rem;
    color: #4d3303;
}

.vision-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115rem;
    height: 40vh;
    z-index: -10;
    /* padding: 2rem; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    color: white;
}

.vision-description {
    display: flex;
    justify-content: center;
    font-family: "Catamaran", serif;
    font-size: 3vh;
    font-weight: 200;
    padding: 1rem;
    color: #4d3303;
}

.secondVision-description {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Philosopher", serif;
    font-size: 3vh;
    font-weight: 200;
    padding: 1rem;
    color: #4d3303;
}

.architect_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    flex-wrap: wrap;
}

.reverse {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10rem;
    padding: 50px 20px;
    flex-wrap: wrap;
}

.card-architect img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 500px;
}

.text-section,
.text-section-inverse {
    padding: 0 20px;
    max-width: 600px;
    text-align: center;
}

.text-section h2,
.text-section-inverse h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.text-section p,
.text-section-inverse p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    margin: 0 auto;
}