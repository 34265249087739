/* 
FONT
*/
.titleFont_journey {
  font-family: "Philosopher", serif;
  font-size: 5.5vh;
  font-weight: 400;
}

.titleFont_incentives {
  font-family: "Catamaran", serif;
  font-size: 5.5vh;
  font-weight: 300;
}

.titleFont_wedding {
  font-family: "Petit Formal Script", serif;
  font-size: 5.5vh !important;
  font-weight: 400;
}

.catamText_fontIncentives {
  font-family: "Catamaran", serif;
  font-size: 2vh;
  font-weight: 400;
  color: #4d3303;
}

.catamText_fontDescrp {
  font-family: "Catamaran", serif;
  font-size: 2vh;
  font-weight: 400;
  color: #4d3303;
}

/* 
genral css
*/

#parallax-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .img-container {
    height: 100vh;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .img-container > div {
    width: 100vw; /* Immagini full screen orizzontale */
    height: 100vh;
    margin: 0;
    background: #f5f5f5;
    overflow: hidden;
  }
  
  .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (max-width: 500px) {
    .img-container > div {
      width: 100vw;
      height: 60vh; /* Ridurre per schermi piccoli */
    }
  
    .img-container img {
      width: 100%;
      height: 60vh;
    }
  }
  
  .img-container h2 {
    color: #4ff0b7;
    margin: 0;
    font-family: "Azeret Mono", monospace;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: -3px;
    line-height: 1.2;
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: #4ff0b7;
    bottom: 50px;
    transform: scaleX(0);
  }

  .explore_button {
    font-family: "Catamaran", serif;
    left: 10px;
    font-size: 20px;
    border-radius: 60%;
    padding: 20px;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    background: transparent;
    color: black;
    border: 1px solid #ebcf88;
    cursor: pointer;
  }
  
  .explore_button:hover {
    color: #e5c67c;
    transform: scale(1.2);
  }